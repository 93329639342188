(function() {
    'use strict';

    angular.module("mfgApp")
        .controller("LicensingCtrl", generateLicenseController);

    generateLicenseController.$inject = ["$scope", "mfgApi", "routesConstant"];

    function generateLicenseController($scope, mfgApi, routesConstant) {
        $scope.$parent.page = $scope.$parent.page || {};
        $scope.$parent.page.title = "Generate aeRos Pro License Key";
        $scope.licenseTypes = [];
        $scope.licenses = [];
        $scope.exportUrl=undefined;
        $scope.MFG = routesConstant.MFG;

        getTerms();

        function getTerms() {
            mfgApi.getLicenseTerms().success(function (data) {
                var terms = data.licenseTerms;
                _.each(terms, function (term) {
                    $scope.licenseTypes.push(term);
                });
            });
        }


        $scope.generateModel = {
            numLicenses: 1
        };

        $scope.generateLicense = function (numLicenses, generateModel) {
            mfgApi.generateLicenses(numLicenses, generateModel)
                .success(function (data) {
                    var licenses = data.licenses;
                    _.each(licenses, function (lic) {
                        $scope.licenses.push(lic);
                    });
                })
                .error(function (err) {
                    console.log("error");
                });
            $scope.submitted = true;
        };


        $scope.exportLicenses = function () {
            var ids = _.map($scope.licenses, 'id');

            $scope.exportUrl=undefined;
            console.log("I will print these licenses: ");
            console.log(ids);
            mfgApi.exportLicenses(ids).
            success(function(data) {
                //Get file name from data
                //import the file as a link now...
                $scope.exportUrl = data.url;
                console.log("export file "+ $scope.exportUrl);
            }).
            error(function (err) {
                $scope.exportUrl=undefined;
                Notification.error("An error occurred generating the export. " + err.message);
            })

        };

        $scope.downloadLicenses = function(exportUrl) {
            mfgApi.downloadLicenses(exportUrl)
            .success(function (data) {
                window.location = data.downloadUrl;
            })
            .error(function (err) {
                Notification.error("An error occurred exporting the export. " + err.message);
            });
        };

        $scope.resetLicenses = function () {
            $scope.exportUrl=undefined;
            $scope.licenses.length=0;
        };
    }

})();
